.assistant-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .assistant-list h2 {
    color: #333;
    text-align: center;
  }
  
  .assistant-list ul {
    list-style-type: none;
    padding: 0;
  }
  
  .assistant-list li {
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 15px;
  }
  
  .assistant-list h3 {
    color: #2c3e50;
    margin-top: 0;
  }
  
  .assistant-list p {
    color: #7f8c8d;
    margin: 5px 0;
  }
  