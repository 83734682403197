/* rpv-toolbar.css */

.rpv-toolbar {
    display: flex;    
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rpv-toolbar button {
/*    background-color: #007bff;    */
    background-color: green;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.rpv-toolbar button:hover {
    background-color: #0056b3;
}
