.quiz-container {
    width: auto;
    max-width: auto; /* or 100px if you prefer */
    margin: auto;
    padding: 20px;
    background-color: #f4f4f4; /* Light background for the whole quiz area */
  }

  .level-controls {
    max-width: auto; /* Consistent with .text-data for alignment */
    margin: auto; /* Center the controls horizontally */
    display: block; /* Ensures the element is block-level, which is usually default */
  }

  .question, .incorrect-question {
    background-color: #23aa53; /* Changed to green as specified */
    color: white;
    padding: 10px;
    margin-bottom: 15px; /* Increased gap between questions */
    border-left: 5px solid #021d0c; /* Darker green border */
  }
  
  .option {
    display: block; /* Ensure each option is on a new line */
    padding: 10px;
    margin-bottom: 2px;
    color: white;
    border-bottom: 1px solid #0e7a36; /* Thin line between options */
  }
  
  .option:nth-child(odd) {
    background-color: #808080; /* Gray for odd options */ 
    /* background-color: #525050; Gray for odd options */    
  }
  
  .option:nth-child(even) {
    background-color: #000000; /* Black for even options */
  }
  
  .control-elements {
    display: flex;
    justify-content: space-between;
    background-color: #eee; /* Light background for controls */
    padding: 10px;
    margin-top: 20px;
  }
  
  .correct-answer {
    background-color: #808080; /* Assuming correct answer follows the odd styling */
    color: white;
    padding: 10px;
    margin-top: 5px;
  }
  
  .submit-button {
    padding: 10px 20px; /* Makes the button larger */
    background-color: blue; /* A deep blue background for high contrast */
    color: #ffffff; /* White text for high contrast */
    border: none; /* Removes the default border */
    cursor: pointer; /* Changes the cursor to a pointer on hover */
    font-size: 16px; /* Increases the font size for better readability */
    font-weight: bold;
    border-radius: 5px; /* Optionally adds rounded corners */
    margin-top: 20px; /* Adds some space above the button */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    margin-left: auto;
  }
  
  .submit-button:hover {
    background-color: #0056b3; /* Darkens the button on hover for a subtle effect */
  }
  
  .text-data {
    white-space: pre-wrap; /* Ensures that whitespace and line breaks are preserved */
/*    background-color: lightblue; /* Light background color */
    background-color: #333; /* Dark background */
    color: white; /* Light text */
    font-family: 'Calibri', sans-serif;
    font-size: 20px; /* Example size, adjust as needed */
    padding: 10px;
    border-left: 3px solid #ccc; /* Stylistic choice for border */
    margin-bottom: 20px; /* Space before the form */
    overflow: auto; /* Adds scroll if text is too long */
    height: auto; /* Adjust height as needed */
    max-height: 300px; /* Maximum height before scrolling */
    width: auto;
    max-width: auto;
    margin: auto;
  }
  
  .line-odd {
    background-color: #333; /* Light grey for odd lines */
  }
  
  .line-even {
    background-color: #333; /* White for even lines */
    color: yellows;
  }
  
/*240702 this change is needed for renering PDF via pdfjs-dist and URL links should be clickable.*/
