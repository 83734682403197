.chatContainer {
    display: flex;
    flex-direction: column-reverse;
    /* height: 600px;
    width: 100%; */
    height: 80%;    
    width: 90%;
    /* background-color: red;  */
    /* justify-content: center; */
    align-items: center;
  }
  
  .inputForm {
    display: flex;
    width: 100%;
    padding: 10px;
    padding-bottom: 40px;
    order: 1;
  }
  
  .input {
    flex-grow: 1;
    padding: 16px 24px;
    margin-right: 10px;
    border-radius: 60px;
    border: 2px solid transparent;
    font-size: 1em;
    background-color: #efefef;
  }
  
  .input:focus {
    outline: none !important;
    border-color: #000;
    background-color: white;
  }
  
  .button {
    padding: 8px 24px;
    background-color: #000;
    color: white;
    border: none;
    font-size: 1em;
    border-radius: 60px; /* Removed duplicate border-radius property */
  }
  
  .button:disabled {
    background-color: lightgrey;
  }
  
  .messages {
    /* height: 400px;
    width: 400px; */
    /* border-radius: 50px; */
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    order: 2;
    white-space: pre-wrap;
  }
  
  .userMessage,
  .assistantMessage,
  .codeMessage {
    margin: 8px 0;
    padding: 8px 20px;
    align-self: flex-start;
    border-radius: 15px;
    max-width: 80%;
    overflow-wrap: break-word;
    white-space: pre-wrap;  /* as of 240923 for reducing space between lines*/
    /* white-space: normal;     */
    word-break: break-word;
    margin-bottom: 10px;
  }
  
  .assistantMessage img {
    max-width: 100%;
    margin: 8px 0px 8px 0px;
    border-radius: 8px;
  }
  
  .userMessage {
    align-self: flex-end;
    color: #fff;
    background-color: #000;
  }
  
  .assistantMessage {
    background-color: #efefef;
  }
  .assistantMessage p{
    /* margin: 0 0 10px 0; */
    margin: 0;
  }

  
  .codeMessage {
    padding: 10px 16px;
    background-color: #e9e9e9;
    font-family: monospace;
    counter-reset: line;
  }
  
  .codeMessage > div {
    margin-top: 4px;
  }
  
  .codeMessage span {
    color: #b8b8b8;
    margin-right: 8px;
  }
  