.upload-file {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .upload-file h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .upload-file input[type="file"] {
    display: block;
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .upload-file select {
    display: block;
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .upload-file button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .upload-file button:hover {
    background-color: #2980b9;
  }
  