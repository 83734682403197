/* main.css */
body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    color: #333;
    line-height: 1.6;
    padding: 20px;
    overscroll-behavior-y: contain;    /* 240919 prevent browser refresh on scroll overflow */
  }
  
  h1, h2 {
    color: #005A9C;
  }
  
  .container {
    max-width: auto;
    margin: auto;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .button {
    display: block;
    width: 50%;
    padding: 10px;
    margin: 10px 0;
    font-size: 18px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .rowDelete {
    display: block;
    width: 20px;
    padding: 5px;
    margin: 5px 0;
    font-size: 10px;
    color: #fff;
    background-color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  Button1 {
    display: block;
    /* width: 10%; */
    padding: 10px;
    margin: 10px 0;
    font-size: 14px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .button:hover, Button:hover {
    background-color: #0056b3;
  }
  
  .button:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,.25);
  }
  
  /* as of 240613, for implementing Urdu fonts */
  @font-face {
    font-family: 'Jameel Noori Nastaleeq';
    src:  local("Jameel Noori Nastaleeq"),
          url('../fonts/Jameel_Noori_Nastaleeq_Regular.ttf') format('truetype'); 
    font-weight: normal;
    font-style: normal;
  }
  
  /* as of 240613, to implekent CSS for react pdf viewer toolbar */
  @import url('./react_pdf_viewer_toolbar.css');

  /* as of 240701, to implement iframe for viewing PDF on mobile devices, in CSS resposive manner */
  iframe {
    width: 100%;
    height: 100vh; /* Full viewport height for better viewing experience */
    border: none; /* Remove default border */
  }
  @media (max-width: 768px) {
    iframe {
        height: 100%; /* Adjust height for mobile devices */
    }
  }

/*240702 this change is needed for renering PDF via pdfjs-dist and URL links should be clickable.*/
