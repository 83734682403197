.create-assistant {
    max-width: 500px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .create-assistant h2 {
    color: #343a40;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .create-assistant form {
    display: flex;
    flex-direction: column;
  }
  
  .create-assistant input,
  .create-assistant textarea,
  .create-assistant select {
    margin-bottom: 1rem;
    padding: 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .create-assistant textarea {
    min-height: 100px;
    resize: vertical;
  }
  
  .create-assistant button {
    background-color: #007bff;
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .create-assistant button:hover {
    background-color: #0056b3;
  }
  
  .tool-select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .vector-store-select {
    width: 100%;
    height: 150px;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    overflow-y: auto;
  }
  
  .vector-store-select option {
    padding: 5px;
  }
  
  .vector-store-select option:checked {
    background-color: #e0e0e0;
  }
  