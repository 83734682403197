.delete-vector-store {
    margin: 20px;
  }
  
  .delete-vector-store h2 {
    margin-bottom: 20px;
  }
  
  .delete-vector-store input {
    display: block;
    margin: 10px 0;
    padding: 8px;
    width: 100%;
    max-width: 400px;
  }
  
  .delete-vector-store button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .delete-vector-store button:hover {
    background-color: #d32f2f;
  }
  
  .delete-vector-store p {
    margin-top: 10px;
  }
  
  